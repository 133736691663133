import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import scholarship_app from '../Rotary Community Service Scholarship Application.pdf';
import sample_service from '../Rotary Example Community Service Hours 2020 Applications.pdf';
import eco_grant_2022 from '../2022 Rotary Economic Development Grant.pdf';

const margin = '5px';
// const rotary_blue = '#005daa';
const useStyles = makeStyles({
    button1: {
        margin: margin,
        // backgroundColor: '#005daa',
        // '&:hover': { backgroundColor: '#0044aa' }
    },
    button2: {
        margin: margin,
        backgroundColor: 'red',
        '&:hover': { backgroundColor: '#aa0000' }
    }
});

export default function Scholarship() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <h1 style={{ color: 'red', fontWeight: 'bold' }}>Scholarship Information</h1>
            {/* <span style={{ fontWeight: 'bold' }}>NEW Deadline: July 31, 2020</span><br /> */}
            {/* <Button className={classes.button1} color='primary' variant='contained' onClick={() => {
                window.open(eco_grant_2022, '_blank')
            }}>2022 Economic Development Grant</Button> */}
            <Button className={classes.button1} color='primary' variant='contained' onClick={() => {
                window.open(scholarship_app, '_blank')
            }}>Download Application</Button>
            <Button className={classes.button2} color='primary' variant='contained' onClick={() => {
                window.open(sample_service, '_blank')
            }}>Sample Service Hours</Button>
        </React.Fragment>
    );
}